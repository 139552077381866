export const environment = {
  gzApi: process.env["VUE_APP_GZ_API"],
  about: process.env["VUE_APP_ABOUT"],
  stud: process.env["VUE_APP_STUD"],
  gw: process.env["VUE_APP_GW"],
  appId: process.env["VUE_APP_Id"],
  qyAppId: process.env["VUE_APP_QY_Id"],
  wxDebug: process.env["VUE_WX_DEBUG"],
  gzPc: process.env["VUE_GZ_PC"],
  copyLink: process.env["VUE_COPY_LINK"],
  captchaId: process.env["VUE_APP_CAPTCHA_ID"],
  authv2Api: process.env["VUE_APP_AUTH_API"],
};
