import gzApiInstance from "@/axios/interceptor/gz";
import { environment } from "@/environment";

function formatterParams(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
}

function tokenPost(params, headerStr) {
  // Salary: wxCode;code;AppId
  // Salary: qyWxCode;code;SuiteId
  // Salary: viriifyCode;Timestamp;EncryptStr;OpenId;UnionId
  // Salary: qyWxVerifyCode;Timestamp;EncryptStr;QyWxOpenUserId
  // Salary: `h5verifyCode;Timestamp;EncryptStr

  return gzApiInstance.post("/account/token", formatterParams(params), {
    headers: {
      Salary: headerStr,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function VirifyCodeGet(mobile, ticket, randstr) {
  return gzApiInstance.post(
    "api/Mobile/Account/VirifyCode?mobile=" +
      mobile +
      `&captchaTicket=${ticket}&captchaRandstr=${randstr}&captchaAppId=${environment.captchaId}`
  );
}
function VirifyCodePut(params) {
  return gzApiInstance.put("api/Mobile/Account/VirifyCode", params);
}
function VirifyCodePost() {
  return gzApiInstance.post("api/Mobile/Account/VirifyCode");
}
//获取当前使用的企业信息
function CompanyGet(companyId) {
  return gzApiInstance.get("api/Mobile/Account/Company?companyId=" + companyId);
}
//获取切换时企业列表信息
function CompanyPost(companyId) {
  return gzApiInstance.post(
    "api/Mobile/Account/Company?companyId=" + companyId
  );
}
//获取工资条明细 通过微信方式获取
function SalaryDetailWXGet(companyId, salaryId, sendType) {
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryDetail?companyId=${companyId}&salaryId=${salaryId}&sendType=${sendType}`
  );
}
//获取工资条明细 通过短信链接获取
function SalaryDetailInfoGet(companyId, salaryId, employeeSn, name) {
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryDetail?companyId=${companyId}&salaryId=${salaryId}&employeeSn=${employeeSn}&name=${name}`
  );
}
//获取工资条明细 通过短信链接是否存在
function SalaryDetailInfoGet2(companyId, salaryId, employeeSn, sendType) {
  console.log(sendType);
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryDetail?companyId=${companyId}&salaryId=${salaryId}&employeeSn=${employeeSn}&sendType=${sendType}`
  );
}
//验证姓名是否正确
function SalaryDetailPost(companyId, salaryId, employeeSn, name, email) {
  return gzApiInstance.post(
    `api/Mobile/Salary/SalaryDetail?companyId=${companyId}&salaryId=${salaryId}&employeeSn=${employeeSn}&name=${name}&email=${email}`
  );
}
//获取企业工资条年份列表
function SalaryListGet1(companyId) {
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryList?companyId=${companyId}`
  );
}
//获取工资列表
function SalaryListGet2(companyId, type, year, index, size) {
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryList?companyId=${companyId}&type=${type}&PayDate=${year}&index=${index}&size=${size}`
  );
}
//获取是否开启安全码
function SecurityCodeGet1() {
  return gzApiInstance.get(`api/Mobile/Account/SecurityCode`);
  // return gzApiInstance.get(`api/Mobile/Account/V16/SecurityCode?CompanyId=${companyId}`);
}
//验证输入的安全码是否正确
function SecurityCodeGet2(code) {
  return gzApiInstance.get(`api/Mobile/Account/SecurityCode?code=${code}`);
}
//设置安全码
function SecurityCodePut(code) {
  return gzApiInstance.put(`api/Mobile/Account/SecurityCode?code=${code}`);
}
//重置安全码
function SecurityCodePost(code, verifyCode, timestamp, encryptStr) {
  return gzApiInstance.post(`api/Mobile/Account/SecurityCode?code=${code}`, {
    code: verifyCode,
    timestamp,
    encryptStr,
  });
}
//获取用户信息
function UserGet() {
  return gzApiInstance.get(`api/Mobile/Account/User`);
}
// api/Mobile/Account/User?name={name}
function UserPost(name) {
  return gzApiInstance.post(`api/Mobile/Account/User?name=${name}`);
}
// 关闭安全码
function SecurityCodePost2() {
  return gzApiInstance.post(`api/Mobile/Account/SecurityCode`);
}
// 解除微信绑定
function UserPut() {
  return gzApiInstance.put(`api/Mobile/Account/User`);
}
// 企业微信 解除绑定
function UserDelete() {
  return gzApiInstance.delete(`api/Mobile/Account/User`);
}
// 是否有工资条
function isSalarylistGet(companyId) {
  return gzApiInstance.get(`api/Mobile/Account/User?companyId=${companyId}`);
}
function urlGet(code) {
  return gzApiInstance.get(`api/url?code=${code}`);
}
// 发送 读取工资记录 put
function SalaryMsgPut(data) {
  return gzApiInstance.put(`api/Mobile/Salary/SalaryMsg`, data);
}
// 发送 读取工资记录 post
function SalaryMsgPost(data) {
  return gzApiInstance.post(`api/Mobile/Salary/SalaryMsg`, data);
}

function SalaryMsgQrcodeGet({ companyId = "", salaryId = "" }) {
  return gzApiInstance.get(
    `api/Mobile/Salary/SalaryMsg?companyId=${companyId}&salaryId=${salaryId}`
  );
}
//获取ticket
function GetTicket(url, qyWxOpenUserId, jsApiTicketType) {
  return gzApiInstance.get(
    `api/Mobile/QyWeiXin/JsSignature?url=${url}&qyWxOpenUserId=${
      qyWxOpenUserId ? qyWxOpenUserId : ""
    }&jsApiTicketType=${jsApiTicketType}`
  );
}

//解除绑定企业微信
function unbindMobile() {
  return gzApiInstance.delete("api/Mobile/Account/User");
}

//获取企业微信用户的信息
function qyWxUserInfo(qyWxOpenUserId) {
  return gzApiInstance.get(
    `api/Mobile/QyWeiXin/QyWxUser?qyWxOpenUserId=${qyWxOpenUserId}`
  );
}

// 获取行为验证加密串
function getAIdEncrypted() {
  return gzApiInstance.get(
    `${environment.authv2Api}/api/TencentCaptcha?captchaAppId=${environment.captchaId}`
  );
}

export default {
  tokenPost,
  VirifyCodeGet,
  VirifyCodePut,
  CompanyGet,
  CompanyPost,
  SalaryDetailWXGet,
  SalaryDetailInfoGet,
  SalaryDetailPost,
  SalaryListGet1,
  SalaryListGet2,
  SecurityCodeGet1,
  SecurityCodeGet2,
  SecurityCodePut,
  SecurityCodePost,
  UserGet,
  VirifyCodePost,
  UserPost,
  SecurityCodePost2,
  UserPut,
  UserDelete,
  isSalarylistGet,
  SalaryDetailInfoGet2,
  urlGet,
  SalaryMsgPut,
  SalaryMsgPost,
  SalaryMsgQrcodeGet,
  GetTicket,
  unbindMobile,
  qyWxUserInfo,
  getAIdEncrypted,
};
